<template>
	<div>
		<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
			<v-card-title>{{ $t('documents.uploadADocument') }}</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-file-input v-model="document" :rules="rules.document" :label="$t('settings.chooseFile')" @change="documentReady = true" />
				</v-form>
			</v-card-text>
		</v-card>

		<div v-if="document" class="text-center">
			<v-dialog v-model="documentReady" width="500">
				<v-card rounded="xl">
					<v-card-title>
						{{ $t('explorer.readyToUpload') }}
					</v-card-title>
					<v-card-text>
						<p>{{ document.name }}</p>
						<v-form ref="formDocType" v-model="docTypeValid">
							<v-text-field v-model="name" :label="$t('auth.name')" :counter="nameMaxLength" :rules="rules.name" outlined />
							<v-select
								v-model="docType"
								:items="availableDocumentTypes"
								:label="$t('documents.docType')"
								:rules="rules.docType"
								required
								item-text="name"
								item-value="key"
								return-object
								outlined
							/>
							<v-checkbox v-model="principal" :label="$t('documents.makePrincipal')" />
							<p class="text-body-2 text-justify">
								{{ $t('documents.makePrincipalExplanation') }}
							</p>
							<v-checkbox v-model="agreeToTerms" :label="$t('documents.agreeToTerms')" :rules="rules.agreeToTerms" required />
						</v-form>
					</v-card-text>

					<v-card-actions>
						<v-spacer />
						<v-btn color="secondary" text rounded @click="clearDocument()">
							{{ $t('settings.clear') }}
						</v-btn>
						<v-btn color="primary" text rounded @click="callUploadDocument()">
							{{ $t('settings.upload') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsChangeEmailForm',
	data() {
		return {
			valid: false,
			document: null,
			name: '',
			docType: null,
			documentReady: false,
			agreeToTerms: false,
			principal: true,
			rules: {
				document: [(v) => !!v || this.$t('rules.required')],
				name: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.nameMaxLength) || this.$t('rules.length', { length: this.nameMaxLength }),
				],
				docType: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (!!v && this.availableDocumentTypes.includes(v)) || `The document type ${v.name} is not supported yet.`,
				],
				agreeToTerms: [(v) => !!v || this.$t('rules.required')],
			},
		}
	},
	computed: {
		nameMaxLength() {
			return 32
		},
		...mapGetters({
			availableDocumentTypes: 'data/availableDocumentTypes',
		}),
	},
	created() {
		this.fetchAvailableDocumentTypes()
	},
	methods: {
		clearDocument() {
			this.document = null
			this.name = ''
			this.docType = null
			this.$refs.form.reset()
			this.$refs.formDocType.reset()
			this.documentReady = false
			this.principal = true
		},
		callUploadDocument() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.$refs.formDocType.validate()
			if (!this.docTypeValid) return
			this.loading = true
			this.uploadDocument({
				name: this.name,
				document: this.document,
				docType: this.docType.key,
				principal: this.principal ? 1 : 0,
			}).then(({ success }) => {
				this.loading = false
				if (success) {
					this.clearDocument()
				}
			})
		},
		...mapActions('documents', ['uploadDocument']),
		...mapActions('data', ['fetchAvailableDocumentTypes']),
	},
}
</script>
